<template>
  <el-dialog v-model="visible" title="请选择用户" width="80%">
    <div>
      <div class="search-row">
        <el-space>
          <template v-if="user.inRoles(roles.prov)">
            <el-switch
              v-model="filter.isFundCountry"
              active-text="直管县"
              inactive-text="市县"
              @change="
                filter.city = filter.town = null;
                query({ pageNum: 1 });
              "
            />

            <el-select
              v-model="filter.town"
              placeholder="直管县"
              clearable
              v-if="filter.isFundCountry"
            >
              <el-option
                :value="item.id"
                :label="item.name"
                :key="item.id"
                v-for="item in fundCountries"
              ></el-option>
            </el-select>

            <el-select
              v-model="filter.city"
              placeholder="市级"
              clearable
              v-if="!filter.isFundCountry"
            >
              <el-option
                v-for="item in dicCity"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
            <el-select
              v-model="filter.town"
              placeholder="区县"
              clearable
              v-if="!filter.isFundCountry"
            >
              <el-option
                v-for="item in dicTown"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </template>
          <el-input
            placeholder="登录名/用户名称/手机"
            v-model="filter.keyword"
            style="width: 500px"
          >
            <template #prepend>关键字</template>
          </el-input>
          <el-button
            type="primary"
            style="margin-left: 5px"
            @click="query({ pageNum: 1 })"
            >查询</el-button
          >
        </el-space>
      </div>
    </div>
    <el-table :data="pagerData.list">
      <el-table-column label="操作" width="100">
        <template #default="{ row: item }">
          <el-popconfirm title="确认委托给该用户吗？" @confirm="select(item)">
            <template #reference>
              <el-button
                :loading="isProcessing"
                type="primary"
                icon="el-icon-check"
                size="mini"
                >选择</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column label="地区" prop="areaName"></el-table-column>
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="手机号" prop="cellPhone"></el-table-column>
    </el-table>
    <el-pagination
      style="padding-left: 5px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      v-model:page-size="filter.pageSize"
      :total="pagerData.count"
      layout="sizes,total, prev, pager, next"
      v-model:currentPage="filter.pageNum"
    ></el-pagination>
  </el-dialog>
</template>
<script lang="ts">
import roles from "@/types/roles.ts";
import { useCityRelTown, usefundCountriesDic } from "@/network/chinaArea";
import {
  defineComponent,
  inject,
  ref,
  onMounted,
  reactive,
  toRefs,
  toRef,
} from "vue";
import { useQuery, useUser, userTypeDic } from "@/network/user.ts";
export default defineComponent({
  props: {
    setting: { type: Object },
  },
  emits: ["selected"],
  setup(props, { emit }) {
    const { visible } = toRefs(props.setting);
    const user: any = inject("user");
    const filter = reactive({
      pageNum: 1,
      pageSize: 20,
      city: null,
      town: null,
      keyword: null,
      isApproved: true,
      userType_Id: null,
      isFundCountry: false,
    });

    if (user.inRoles(roles.fundCountyManager)) {
      filter.town = user.county_ID;
    } else if (user.inRoles(roles.city)) {
      filter.city = user.city_ID;
    }

    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town")
    );
    const { data, query } = useQuery(filter);

    const [isProcessing, , , , , toggleLock, remove] = useUser(null);

    // const doQuery = () => {
    //   filter.pageNum = 1;
    //   query();
    // };
    const select = (item) => {
      emit("selected", item.id);
      visible.value = false;
    };
    return {
      visible,
      user,
      roles,
      isProcessing,
      toggleLock,
      remove,
      userTypeDic: userTypeDic(),
      dicCity,
      dicTown,
      fundCountries: usefundCountriesDic(),
      pagerData: data,
      query,
      filter,
      handleCurrentChange: (e) => {
        filter.pageNum = e;
        query();
      },
      handleSizeChange: (e) => {
        filter.pageSize = e;
        query();
      },
      select,
    };
  },
});
</script>
<style lang="scss" scoped></style>
