<template>
  <el-space :size="10" v-if="allowSelect">
    <el-select clearable v-model="doc.doc_Type" placeholder="请选择(可输入筛选)" filterable :disabled="isLoadingDocTypes"
      @change="() => {
    clearId();
    doc.doc_Year = null
  }">
      <el-option v-for="i in docTypes" :key="i.value" :label="i.label" :value="parseInt(i.value)"></el-option>
    </el-select>
    <el-select clearable filterable v-model="doc.doc_Year" placeholder="请选择" :disabled="isLoadingDocYears" @change="() => {
    clearId();
  }">
      <el-option v-for="i in docYears" :key="i" :label="i" :value="i"></el-option>
    </el-select>

    <el-select clearable :disabled="isLoadingDocNumber" v-model="doc.id" placeholder="请输入编号" filterable>
      <el-option v-for="i in docNumbers" :key="i.value" :label="i.isMuliNo ? `(${i.muliNoKey})${i.label}` : i.label"
        :value="i.value"></el-option>
    </el-select>
    号
  </el-space>
  <showDocContentBtn :doc="doc" v-show="!isLoading" @show="showDocContent"></showDocContentBtn>
</template>

<script lang="ts">
import { isEmpty } from "@/utils/validate";
import showDocContentBtn from "@/views/officialDoc/showDocContentBtn.vue";
import {
  useOfficialDoc,
  useGetOfficialDocTypes,
  useGetOfficialDocYears,
  useGetOfficialDocNumbers,
} from "@/network/officialDoc.ts";
import { defineComponent, onMounted, reactive, ref, toRefs, watch } from "vue";

export default defineComponent({
  components: {
    showDocContentBtn,
  },
  emits: ["update:modelValue", "change", "showDocContent"],
  props: {
    modelValue: {
      type: Number,
    },
    allowSelect: {
      type: Boolean,
      default: true,
    },
    category: {
      type: Number,
      default: null,
    },
  },

  setup(props, { emit }) {
    const [isLoading, doc, find] = useOfficialDoc();
    const [isLoadingDocTypes, docTypes] = useGetOfficialDocTypes();
    const [isLoadingDocYears, docYears, fillDocYear] = useGetOfficialDocYears();
    const [isLoadingDocNumber, docNumbers, fillDocNumber] =
      useGetOfficialDocNumbers();
    const showDocContent = (id) => {
      emit("showDocContent", id);
    };
    const clearId = () => {
      doc.doc_Serial_No = null;
      doc.id = null;
      doc.doc_Title = null;
      doc.doc_Compile_No = null;
      doc.isMuliNo = false;
      doc.muliNoKey = null;
      emit("update:modelValue", null);
    };


    onMounted(() => {

      const fetchData = props.modelValue ? find(props.modelValue) : Promise.resolve(null);
      fetchData.then(() => {

        watch(() => props.modelValue, () => {
          if (props.modelValue != 0 && props.modelValue) {
            isLoadingDocYears.value = true
            isLoadingDocNumber.value = true


            find(props.modelValue).then(() => {
              isLoadingDocYears.value = false
              isLoadingDocNumber.value = false
            })
          }
          //doc.id = props.modelValue
        })
        watch(
          () => doc.doc_Type,
          (v, o) => {
            docYears.value = null;
            if (!isEmpty(doc.doc_Type)) {
              fillDocYear(doc.doc_Type, props.category);
            }
            //console.log(v, o, doc.doc_Type);
          }
          , { immediate: true }
        );
        watch(
          () => doc.doc_Year,
          () => {
            docNumbers.value = null;
            if (!isEmpty(doc.doc_Year) && !isEmpty(doc.doc_Type))
              fillDocNumber(doc.doc_Type, doc.doc_Year, props.category);
          }
          , { immediate: true }
        );
        watch(
          () => doc.id,
          (v) => {
            if (!isLoadingDocNumber.value && !isLoadingDocYears.value) {
              if (doc.id) {
                const selected = docNumbers.value?.find((p) => p.value == doc.id);
                doc.id = selected?.id;
                doc.doc_Serial_No = selected?.doc_Serial_No;
                doc.doc_Title = selected?.doc_Title;
                doc.doc_Compile_No = selected?.doc_Compile_No;
                doc.isMuliNo = selected?.isMuliNo;
                doc.muliNoKey = selected?.muliNoKey;
                emit("update:modelValue", selected?.id);
              } else {
                clearId()
              }
            }

          }
        );
      });

      // if (props.modelValue) {


      // const stop = watch(
      //   [isLoadingDocTypes, isLoadingDocYears, isLoadingDocNumber],
      //   () => {
      //     if (
      //       !isLoadingDocTypes.value &&
      //       !isLoadingDocYears.value &&
      //       !isLoadingDocNumber.value &&
      //       props.modelValue
      //     ) {
      //       find(props.modelValue).then(() => {
      //         console.log(doc)
      //       });
      //       stop();
      //     }
      //   }
      // );
      // }
    });
    return {
      doc,
      isLoading,
      isLoadingDocTypes,
      isLoadingDocYears,
      isLoadingDocNumber,
      docTypes,
      docYears,
      docNumbers,
      showDocContent,
      clearId,
    };
  },
});
</script>

<style scoped></style>