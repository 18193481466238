<template>
  <div v-show="id">
    <a href="javascript:void(0)" @click="showRelChange">
      <slot name="empty" v-if="!id"></slot>
      <slot v-else> {{ label }}</slot>
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs } from "vue";

export default defineComponent({
  props: ["id", "label"],
  setup(props, { emit }) {
    const showRelChange = () => {
      emit("link", props.id);
    };
    return {
      showRelChange,
    };
  },
});
</script>

<style scoped>
</style>