
import roles from "@/types/roles.ts";
import { useCityRelTown, usefundCountriesDic } from "@/network/chinaArea";
import {
  defineComponent,
  inject,
  ref,
  onMounted,
  reactive,
  toRefs,
  toRef,
} from "vue";
import { useQuery, useUser, userTypeDic } from "@/network/user.ts";
export default defineComponent({
  props: {
    setting: { type: Object },
  },
  emits: ["selected"],
  setup(props, { emit }) {
    const { visible } = toRefs(props.setting);
    const user: any = inject("user");
    const filter = reactive({
      pageNum: 1,
      pageSize: 20,
      city: null,
      town: null,
      keyword: null,
      isApproved: true,
      userType_Id: null,
      isFundCountry: false,
    });

    if (user.inRoles(roles.fundCountyManager)) {
      filter.town = user.county_ID;
    } else if (user.inRoles(roles.city)) {
      filter.city = user.city_ID;
    }

    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town")
    );
    const { data, query } = useQuery(filter);

    const [isProcessing, , , , , toggleLock, remove] = useUser(null);

    // const doQuery = () => {
    //   filter.pageNum = 1;
    //   query();
    // };
    const select = (item) => {
      emit("selected", item.id);
      visible.value = false;
    };
    return {
      visible,
      user,
      roles,
      isProcessing,
      toggleLock,
      remove,
      userTypeDic: userTypeDic(),
      dicCity,
      dicTown,
      fundCountries: usefundCountriesDic(),
      pagerData: data,
      query,
      filter,
      handleCurrentChange: (e) => {
        filter.pageNum = e;
        query();
      },
      handleSizeChange: (e) => {
        filter.pageSize = e;
        query();
      },
      select,
    };
  },
});
