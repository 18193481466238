<template>
  <ShowRelBtn :id="doc.id" @link="link">
    <i class="el-icon-link"></i>
    {{ (doc.isMuliNo ? `(${doc.muliNoKey})` : "") + doc.doc_Compile_No }}
    <span v-show="doc.doc_Title">|</span> {{ doc.doc_Title }}
  </ShowRelBtn>
</template>

<script lang="ts">
import ShowRelBtn from "@/components/showRelBtn.vue";
import { defineComponent, reactive } from "vue";

export default defineComponent({
  components: {
    ShowRelBtn,
  },
  props: ["doc"],
  setup(props, { emit }) {
    const link = (v) => {
      emit("show", v);
    };
    return {
      link,
    };
  },
});
</script>

<style scoped>
</style>