
import officialDocSelectAndCreate from "@/views/officialDoc/offcialDocSelectAndCreate.vue";
import FileuploadListV1 from "@/components/fileuploadListV1.vue";
import roles from "@/types/roles.ts";
import {
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  toRef,
  Ref,
  PropType,
  computed,
  onMounted
} from "vue";
import { attachSetting } from "@/network/lookUp.ts";
import FileuploadList from "@/components/fileuploadList.vue";
import { useFundWatchAction } from "@/network/fundWatch";
export default defineComponent({
  components: {
    FileuploadList,
    FileuploadListV1,
    officialDocSelectAndCreate
  },
  props: {
    setting: { type: Object },

  },
  emits: ["change", "submit"],
  setup(props, { emit }) {
    const user: any = inject("user");
    const { visible, id, title } = toRefs(props.setting as any);
    const form = reactive({
      finishCheckStatus: null,
      finishCheckMemo: null,
      finishCheckAttachList: [],
      finishCheckReviewAttachList: [],
      finishCheckReviewMemo: ""
    });
    const fileuploadSetting = reactive({
      fileList: toRef(form, "finishCheckAttachList"),
      accpet: ".pdf",
      urlKey: "filePath",
      nameKey: "fileName"

    });

    const [isLoading, , , , , projectFinishCheck] = useFundWatchAction();
    const finishCheck = (status, isSave = false) => {
      projectFinishCheck(id.value, {
        finishCheckStatus: status,
        finishCheckMemo: form.finishCheckMemo,
        finishCheckAttachList: form.finishCheckAttachList,
        finishCheckReviewMemo: form.finishCheckReviewMemo,
        finishCheckReviewAttachList: form.finishCheckReviewAttachList
      }).then(p => {
        emit("change");

        form.finishCheckAttachList = p.finishCheckAttachList;
        form.finishCheckReviewAttachList = p.finishCheckReviewAttachList;
        if (!isSave) {
          visible.value = false;
        }
      })
    }
    // watch(
    //   () => props.setting.data,
    //   () => {

    //     form.finishCheckAttachList = props.setting.data.finishCheckAttachList || [];
    //     form.finishCheckStatus = props.setting.data.finishCheckStatus;
    //     form.finishCheckMemo = props.setting.data.finishCheckMemo;
    //     form.finishCheckReviewAttachList = props.setting.data.finishCheckReviewAttachList || [];
    //     form.finishCheckReviewMemo = props.setting.data.finishCheckReviewMemo;
    //     //Object.assign(form, props.setting.data);
    //   }
    // );

    onMounted(() => {
      form.finishCheckAttachList = props.setting.data.finishCheckAttachList || [];
      form.finishCheckStatus = props.setting.data.finishCheckStatus;
      form.finishCheckMemo = props.setting.data.finishCheckMemo;
      form.finishCheckReviewAttachList = props.setting.data.finishCheckReviewAttachList || [];
      form.finishCheckReviewMemo = props.setting.data.finishCheckReviewMemo;
    })
    return {
      user,
      roles,
      fileuploadSetting,
      attachSetting,
      form,
      finishCheck,
      title,
      visible
    };
  },
});
