
import { defineComponent, toRefs } from "vue";

export default defineComponent({
  props: ["id", "label"],
  setup(props, { emit }) {
    const showRelChange = () => {
      emit("link", props.id);
    };
    return {
      showRelChange,
    };
  },
});
