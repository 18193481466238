<template>
  <el-dialog v-model="visible" :title="`申请验收 - ${title}`">
    <el-form :model="form" label-width="200px" :disabled="form.finishCheckStatus">
      <el-form-item label>
        <el-alert title="等待验收中..." type="info" show-icon v-if="form.finishCheckStatus == 1"></el-alert>
        <el-alert title="已通过验收" type="success" show-icon v-else-if="form.finishCheckStatus == 2"></el-alert>
      </el-form-item>
      <el-form-item label="验收文件">
        <FileuploadList :setting="fileuploadSetting"></FileuploadList>
      </el-form-item>
      <el-form-item label="验收说明">
        <el-input v-model="form.finishCheckMemo" placeholder="验收说明" type="textarea" :rows="5"></el-input>
      </el-form-item>

      <template v-if="form.finishCheckStatus > 0">
        <el-form :model="form" label-width="200px" :disabled="form.finishCheckStatus == 2">
          <el-form-item label="验收审核意见">
            <el-input v-model="form.finishCheckReviewMemo" placeholder="验收审核意见" type="textarea" :rows="5"
              :disabled="!user.inRoles(roles.prov)"></el-input>
          </el-form-item>
          <el-form-item label="验收审核文件">
            <FileuploadListV1 v-model:files="form.finishCheckReviewAttachList" :disabled="!user.inRoles(roles.prov)"
              accpet=".pdf" urlKey="filePath" nameKey="fileName">
            </FileuploadListV1>
          </el-form-item>
          <el-form-item label="验收批准文件">
            <officialDocSelectAndCreate :category="4"></officialDocSelectAndCreate>
          </el-form-item>

        </el-form>
      </template>



    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
        <el-button type="success" icon="el-icon-check" @click="finishCheck(form.finishCheckStatus, true)"
          v-if="form.finishCheckStatus < 2">保存</el-button>
        <el-popconfirm title="确认提交申请验收吗！？" @confirm="finishCheck(1)" v-if="!form.finishCheckStatus">
          <template #reference>
            <el-button type="primary" icon="el-icon-arrow-right">申请验收</el-button>
          </template>
        </el-popconfirm>

        <template v-if="form.finishCheckStatus == 1 && user.inRoles(roles.prov)">
          <el-popconfirm title="确认退回验收申请吗！？" @confirm="finishCheck(0)" placement="top">
            <template #reference>
              <el-button type="danger" icon="el-icon-refresh-left">退回</el-button>
            </template>
          </el-popconfirm>
          <el-popconfirm title="第三方评审" @confirm="finishCheck(2)" placement="top" v-if="form.finishCheckStatus == 1">
            <template #reference>
              <el-button type="primary" icon="el-icon-check">提交评审</el-button>
            </template>
          </el-popconfirm>
          <el-popconfirm title="确认通过验收申请吗！？" @confirm="finishCheck(3)" placement="top"  v-if="form.finishCheckStatus == 2">
            <template #reference>
              <el-button type="primary" icon="el-icon-check">通过验收</el-button>
            </template>
          </el-popconfirm>
        </template>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import officialDocSelectAndCreate from "@/views/officialDoc/offcialDocSelectAndCreate.vue";
import FileuploadListV1 from "@/components/fileuploadListV1.vue";
import roles from "@/types/roles.ts";
import {
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  toRef,
  Ref,
  PropType,
  computed,
  onMounted
} from "vue";
import { attachSetting } from "@/network/lookUp.ts";
import FileuploadList from "@/components/fileuploadList.vue";
import { useFundWatchAction } from "@/network/fundWatch";
export default defineComponent({
  components: {
    FileuploadList,
    FileuploadListV1,
    officialDocSelectAndCreate
  },
  props: {
    setting: { type: Object },

  },
  emits: ["change", "submit"],
  setup(props, { emit }) {
    const user: any = inject("user");
    const { visible, id, title } = toRefs(props.setting as any);
    const form = reactive({
      finishCheckStatus: null,
      finishCheckMemo: null,
      finishCheckAttachList: [],
      finishCheckReviewAttachList: [],
      finishCheckReviewMemo: ""
    });
    const fileuploadSetting = reactive({
      fileList: toRef(form, "finishCheckAttachList"),
      accpet: ".pdf",
      urlKey: "filePath",
      nameKey: "fileName"

    });

    const [isLoading, , , , , projectFinishCheck] = useFundWatchAction();
    const finishCheck = (status, isSave = false) => {
      projectFinishCheck(id.value, {
        finishCheckStatus: status,
        finishCheckMemo: form.finishCheckMemo,
        finishCheckAttachList: form.finishCheckAttachList,
        finishCheckReviewMemo: form.finishCheckReviewMemo,
        finishCheckReviewAttachList: form.finishCheckReviewAttachList
      }).then(p => {
        emit("change");

        form.finishCheckAttachList = p.finishCheckAttachList;
        form.finishCheckReviewAttachList = p.finishCheckReviewAttachList;
        if (!isSave) {
          visible.value = false;
        }
      })
    }
    // watch(
    //   () => props.setting.data,
    //   () => {

    //     form.finishCheckAttachList = props.setting.data.finishCheckAttachList || [];
    //     form.finishCheckStatus = props.setting.data.finishCheckStatus;
    //     form.finishCheckMemo = props.setting.data.finishCheckMemo;
    //     form.finishCheckReviewAttachList = props.setting.data.finishCheckReviewAttachList || [];
    //     form.finishCheckReviewMemo = props.setting.data.finishCheckReviewMemo;
    //     //Object.assign(form, props.setting.data);
    //   }
    // );

    onMounted(() => {
      form.finishCheckAttachList = props.setting.data.finishCheckAttachList || [];
      form.finishCheckStatus = props.setting.data.finishCheckStatus;
      form.finishCheckMemo = props.setting.data.finishCheckMemo;
      form.finishCheckReviewAttachList = props.setting.data.finishCheckReviewAttachList || [];
      form.finishCheckReviewMemo = props.setting.data.finishCheckReviewMemo;
    })
    return {
      user,
      roles,
      fileuploadSetting,
      attachSetting,
      form,
      finishCheck,
      title,
      visible
    };
  },
});
</script>

<style scoped></style>