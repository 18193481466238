
import ShowRelBtn from "@/components/showRelBtn.vue";
import { defineComponent, reactive } from "vue";

export default defineComponent({
  components: {
    ShowRelBtn,
  },
  props: ["doc"],
  setup(props, { emit }) {
    const link = (v) => {
      emit("show", v);
    };
    return {
      link,
    };
  },
});
