import { reactive } from "vue";

// export interface showSetting {
//   id: number;
//   visible: boolean;
// }

export function reactiveShowSetting(setting = null) {
  return reactive(Object.assign({ id: 0, visible: false }, setting));
}
