<template>
    <DocContent :setting="docShowSetting" v-if="docShowSetting.visible"></DocContent>
    <Manage :setting="manageSetting" @change="changed" v-if="manageSetting.visible"></Manage>
    <el-space style="align-items: start;">
        <officialDocSelect ref="refDocSelect" v-model="form.doc_Id" @showDocContent="showDocContent"
            :category="category">
        </officialDocSelect>
        <el-button type="primary" icon="el-icon-plus" @click="create" v-if="user.inRoles(roles.prov)">创建</el-button>
    </el-space>
    <p>* 请从批文库选择相关批文，如无相关批文，{{user.inRoles(roles.prov) ? '请先通过创建按钮添加。':'请通过批文库模块创建批文。'}}</p>
</template>
<script lang="ts">
import roles from "@/types/roles.ts";
import Manage from "./manage.vue";
import { reactiveShowSetting } from "@/types/showSetting.ts";
import { defineComponent, onMounted, reactive, ref, toRefs, watch, inject } from "vue";
import officialDocSelect from "@/views/officialDoc/officialDocSelect.vue";
import { DocComponents, useInitOfficialDocObj } from "@/network/officialDoc.ts";
export default defineComponent({
    components: {
        ...DocComponents,
        Manage,
        officialDocSelect,
    },
    props: ["category", "modelValue"],
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const user: any = inject("user");
        const manageSetting = reactive({
            visible: false,
            id: null,
        });

        const docShowSetting = reactiveShowSetting();
        const showDocContent = (id) => {
            docShowSetting.visible = true;
            docShowSetting.id = id;
        };
        const create = () => {
            manageSetting.id = null;
            manageSetting.visible = true;
        }
        const changed = (value) => {
            form.doc_Id = value;
            //emit("update:modelValue", value)
            // console.log( form.doc_Id )
        }
        const form = reactive({ doc_Id: null })




        onMounted(() => {
            //form.doc_Id = props.modelValue

            watch(() => props.modelValue, (v) => {
                form.doc_Id = v;
            }, { immediate: true })

            watch(() => form.doc_Id, (v) => {
                //console.log(v);
                if (props.modelValue != v) {
                    emit("update:modelValue", v)
                }
            })
        })
        return {
            changed,
            roles,
            user,
            create,
            form,
            docShowSetting,
            showDocContent,
            manageSetting
        }
    }

});
</script>